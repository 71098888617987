import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = ({ data }) => {
  const navigate = useNavigate();
  const goToRoute = (link) => {
    navigate(link);
  };
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <h4 className="head_title">Quick Links</h4>
            <ul className="list-unstyled links">
              <li>
                <a onClick={() => goToRoute("/career")}>CAREERS</a>
              </li>
              <li>
                <a onClick={() => goToRoute("/contact")}>CONTACT US</a>
              </li>
              <li className="divider">
                <a href="#">SITEMAP</a>
              </li>
              <li className="divider">
                <a href="#">COOKIE NOTICES</a>
              </li>
              <li>
                <a href="#">PRIVACY STATEMENT</a>
              </li>
              <li>
                <a href="#">TERMS AND CONDITIONS</a>
              </li>
            </ul>
          </div>

          <div className="col-md-3">
            <h4 className="head_title">News & Media</h4>
            <ul className="list-unstyled links">
              <li>
                <a onClick={() => goToRoute("/news")}>NEWS</a>
              </li>
              <li>
                <a onClick={() => goToRoute("/whoweare")}>PUBLICATIONS</a>
              </li>
              <li>
                <a onClick={() => goToRoute("/gallery")}>MEDIA GALLERY</a>
              </li>
            </ul>
          </div>

          <div className="col-md-3">
            <h4 className="head_title">Other Website</h4>
            <ul className="list-unstyled links">
              <li>
                <a href={`https://${data?.otherWebsite}`} target="_blank">
                  YAMAC TRADING COMPANY
                </a>
              </li>
            </ul>
          </div>

          <div className="col-md-3">
            <h4 className="head_title">Social Media</h4>
            <ul className="list-unstyled social">
              <li className="twitter">
                <a
                  href={`https://twitter.com/${data?.twitter}`}
                  target="_blank"
                >
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li className="linkedin">
                <a
                  href={`https://linkedIn.com/${data?.linkedIn}`}
                  target="_blank"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
              <li className="youtube">
                <a
                  href={`https://youtube.com/${data?.youtube}`}
                  target="_blank"
                >
                  <i className="fab fa-youtube"></i>
                </a>
              </li>
              <li className="insta">
                <a
                  href={`https://instagram.com/${data?.instagram}`}
                  target="_blank"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li className="facebook">
                <a
                  href={`https://facebook.com/${data?.facebook}`}
                  target="_blank"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <p className="copy">
          {data?.bottomline
            ? data.bottomline
            : "© 2020 Yamac Engineering and Construction PVT LTD Co."}
        </p>
      </div>
    </div>
  );
};

export default Footer;
