import React from "react";

const WhyClientsChooseUs = ({
  heading,
  heading2,
  heading3,
  paragraph,
  btnTxt,
}) => {
  return (
    <div className="container">
      <h2 className="heading">{heading}</h2>
      <div
        className="feature"
        style={{ backgroundImage: "url(images/feature.jpg)" }}
      >
        <div className="row h-100">
          <div className="col-md-5 offset-md-7 h-100">
            <div className="details">
              <h5>{heading2}</h5>
              <h3>{heading3}</h3>
              <p>{paragraph}</p>
              <button className="btn btn-outline-light">{btnTxt}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyClientsChooseUs;
