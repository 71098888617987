import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import PageDrawer from "./pages/PageDrawer";
import masterJSON from "./Jsons/masterJSON";

function App() {
  console.log = () => {};
  return (
    <BrowserRouter>
      <Routes>
        {masterJSON.map((data, index) => (
          <Route
            key={index}
            path={data.route}
            element={<PageDrawer data={data} />}
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
