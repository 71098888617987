import React from "react";
import Banner from "../components/Banner";
import LatestNews2 from "../components/LatestNews2";
import JoinOurTeamBanner from "../components/JoinOurTeamBanner";

const News = ({ data }) => {
  return (
    <>
      <Banner {...data.banner} />
      <LatestNews2 {...data.latestNews2} />
      <JoinOurTeamBanner {...data.arabianSun} />
      <JoinOurTeamBanner {...data.joinOurTeam} />{" "}
    </>
  );
};

export default News;
