export default {
  name: "GALLERY",
  route: "/gallery",
  banner: {
    imageSource: "gallery.jpg",
    heading: "Media Gallery",
    paragraph:
      "When we put our energy to work, we enable others to seize opportunities that can revolutionize the world.",
  },
  latestNews2: {
    label: "Latest News",
    items: [
      {
        image: "n1.jpg",
        headline:
          "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",
      },
      {
        image: "n1.jpg",
        headline:
          "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",
      },
      {
        image: "n1.jpg",
        headline:
          "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",
      },
      {
        image: "n1.jpg",
        headline:
          "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",
      },
      {
        image: "n1.jpg",
        headline:
          "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",
      },
      {
        image: "n1.jpg",
        headline:
          "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",
      },
      {
        image: "n1.jpg",
        headline:
          "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",
      },
      {
        image: "n1.jpg",
        headline:
          "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",
      },
      {
        image: "n1.jpg",
        headline:
          "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",
      },
      {
        image: "n1.jpg",
        headline:
          "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",
      },
      {
        image: "n1.jpg",
        headline:
          "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",
      },
      {
        image: "n1.jpg",
        headline:
          "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",
      },
      {
        image: "n1.jpg",
        headline:
          "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",
      },
      {
        image: "n1.jpg",
        headline:
          "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",
      },
      {
        image: "n1.jpg",
        headline:
          "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",
      },
      {
        image: "n1.jpg",
        headline:
          "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",
      },
      {
        image: "n1.jpg",
        headline:
          "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",
      },
      {
        image: "n1.jpg",
        headline:
          "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",
      },
      {
        image: "n1.jpg",
        headline:
          "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",
      },
    ],
  },
  arabianSun: {
    background: "feature",
    isReverse: true,
    extraBtnText: "DOWNLOAD ISSUE (PDF, 3.7 MB)",
    date: " 15, 2020",
    title: "The Arabian Sun",
    text: "In this week's issue: Saudi Yamac hosting the annual International Petroleum Technology Conference",
    btnTxt: "Open Feature",
  },
  joinOurTeam: {
    background: "feature",
    isReverse: false,
    date: "CAREER",
    title: "Join our team",
    text: "Every day, our multi-national team of more than 70,000 people combines passion, purpose, and performance to achieve a common objective: unlocking the full potential of the Kingdom’s resources.",
    btnTxt: "Find Out More",
  },
};
