import React from "react";

const FAQs = ({ QAs, currentOpenings }) => {
  return (
    <div className="col-md-8">
      {QAs.map((QnA) => (
        <>
          <h5>{QnA.title}</h5>
          <p>{QnA.text}</p>
        </>
      ))}
      <h5>{currentOpenings.title}</h5>

      <div className="card card-body">
        <p>{currentOpenings.p1}</p>
        {currentOpenings.items.map((item) => (
          <>
            <h6>{item.title}</h6>
            <p>{item.text}</p>
          </>
        ))}

        <button className="btn btn-dark">{currentOpenings.btnTxt}</button>
      </div>
    </div>
  );
};

export default FAQs;
