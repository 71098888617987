import React from "react";
import Banner from "../components/Banner";
import ContactPanel from "../components/ContactPanel";
import Map from "../components/Map";

const Contact = ({ data }) => {
  return (
    <>
      <Banner {...data.banner} />
      <ContactPanel {...data.contactPanel} />
      <Map link={data.mapLink} />
    </>
  );
};

export default Contact;
