import React from "react";
import { useNavigate } from "react-router-dom";

const Header = ({ data }) => {
  const navigate = useNavigate();
  const goToRoute = (link) => {
    navigate(link);
  };
  return (
    <div>
      <div className="mobile_toggle">
        <div className="container-fluid">
          <a onClick={() => goToRoute("/")} className="logo">
            <img src="images/logo.png" alt="" />
          </a>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fa fa-bars"></i> Menu
          </button>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg">
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div className="top_bar">
            <div className="container">
              <ul className="left_links list-unstyled">
                <li>
                  <a onClick={() => goToRoute("/career")}>Career</a>
                </li>
                <li>
                  <a onClick={() => goToRoute("/news")}>News & Media </a>
                </li>
                <li>
                  <a onClick={() => goToRoute("/whoweare")}>Yamac Magazine</a>
                </li>
              </ul>

              <ul className="right_links list-unstyled">
                <li>
                  <i className="fa fa-phone"></i> {data?.cell}
                </li>
              </ul>
            </div>
          </div>

          <div className="mid_header">
            <div className="container">
              <div className="desktopLogo">
                <a href="#">
                  <img src="images/logo.png" alt="" />
                </a>
              </div>

              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    aria-current="page"
                    onClick={() => goToRoute("/whoweare")}
                  >
                    Who we are
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={() => goToRoute("/career")}>
                    Creating value
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={() => goToRoute("/contact")}>
                    Partnering with us
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={() => goToRoute("/career")}>
                    Investors
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
