import React from "react";
import Banner from "../components/Banner";
import ContactUs from "../components/ContactUs";
import FAQs from "../components/FAQs";
import JoinOurTeamBanner from "../components/JoinOurTeamBanner";

const Career = ({ data }) => {
  return (
    <>
      <Banner {...data.banner} />
      <div className="container">
        <div className="career">
          <div className="row">
            <FAQs {...data.FAQs} />
            <ContactUs {...data.contact} />
          </div>
        </div>
      </div>
      <JoinOurTeamBanner {...data.arabianSun} />
      <JoinOurTeamBanner {...data.joinOurTeam} />
    </>
  );
};

export default Career;
