import React from "react";
import Layout from "../layout";
import WhoWeAreScreen from "../screens/WhoWeAre";

const WhoWeAre = ({ data }) => {
  return (
    <Layout>
      <WhoWeAreScreen data={data} />
    </Layout>
  );
};

export default WhoWeAre;
