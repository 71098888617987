import React from "react";
import {
  HOME,
  GALLERY,
  NEWS,
  CAREER,
  CONTACT,
  WHO_WE_ARE,
} from "../Jsons/Constants";
import Career from "./Career";
import Contact from "./Contact";
import Gallery from "./Gallery";
import Home from "./Home";
import News from "./News";
import WhoWeAre from "./WhoWeAre";

const PageDrawer = ({ data }) => {
  switch (data.name) {
    case HOME:
      return <Home data={data} />;
    case GALLERY:
      return <Gallery data={data} />;
    case NEWS:
      return <News data={data} />;
    case CAREER:
      return <Career data={data} />;
    case CONTACT:
      return <Contact data={data} />;
    case WHO_WE_ARE:
      return <WhoWeAre data={data} />;
    default:
      return <Home data={data} />;
  }
};

export default PageDrawer;
