import React from "react";
import Layout from "../layout";
import GalleryScreen from "../screens/Gallery";

const Gallery = ({ data }) => {
  return (
    <Layout>
      <GalleryScreen data={data} />
    </Layout>
  );
};

export default Gallery;
