export default {
  name: "CONTACT",
  route: "/contact",
  banner: {
    imageSource: "contact.jpg",
    heading: "Contact",
    paragraph: "We are passionate about what we do.",
  },
  contactPanel: {
    info: [
      {
        icon: "far fa-clock",
        paragraphs: ["Office hours", "Monday to Friday 9am – 6pm"],
      },
      {
        icon: "fa fa-map-marker-alt",
        paragraphs: ["19th Floor of iRise Tower,", "Barsha Heights, Dubai."],
      },
      {
        icon: "far fa-envelope",
        paragraphs: [
          "info@yamac.com",
          "T: +971 4 425 0600",
          " F: +971 4 425 0600",
        ],
      },
    ],
    writeToUs: {
      title: "Write To Us",
      inputs: [
        { type: "text", placeholder: "Mr/Mrs", label: "Mr/Mrs" },
        { type: "text", placeholder: "Name/Surname", label: "Name/Surname" },
        { type: "email", placeholder: "Email", label: "Email" },
        {
          type: "text",
          placeholder: "Contact Number",
          label: "Contact Number",
        },
        { type: "text", placeholder: "Country", label: "Country" },
        { type: "text", placeholder: "Subject", label: "Subject" },
      ],
      subject: "Subject",
    },
    btnTxt: "Send Message",
  },
  mapLink:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.1532900740735!2d55.17891695048778!3d25.09667184185166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6b787d441961%3A0x15f253a6cf7c306c!2sI-Rise+-+Dubai!5e0!3m2!1sen!2sae!4v1513091834582",
};
