export default {
  name: "WHO_WE_ARE",
  route: "/whoweare",
  banner: {
    imageSource: "who_we.png",
    heading: "Your Trust is Our Pride",
    paragraph:
      "When we put our energy to work, we enable others to seize opportunities that can revolutionize the world.",
  },
  whoWeAre: {
    heading1: "Why We Are Best Choice For You?",
    heading2:
      "We believe in the power of energy to transform lives, enhance communities, advance human progress, and sustain our planet.",
    paragraphs1: [
      "With global population forecast to increase by two billion over the next 25 years, even more energy will be required to meet rising demand. It will take all available sources of energy to fill this need — both legacy and alternative sources. And while alternative energy sources are steadily making advances, they will not be capable of meeting future demand alone. Even as we transition to a low emissions future, hydrocarbon-based energy sources will be counted on to meet the bulk of the world’s energy demand well into the future.",
      ,
      "We’re committed to driving energy efficiency and addressing the global emissions challenge. And as the world's largest integrated oil and gas company, we believe we are uniquely qualified to make effective contributions to the overall solution.",
    ],
    image: "inner_cms1.jpg",
    paragraphs2: [
      "We have already demonstrated the potential of technology to reduce emissions from both mobile and stationary sources. We are also capturing emissions and turning them into useful industrial products and manufacturing feedstocks that boost economic growth and job creation. These successes, along with other strategies, have given us one of the lowest CO2 footprints in the oil and gas industry.",
      "At Saudi Yamac, we are proactively doing our part to reliably and sustainably provide the energy products that enable people to seize opportunities that can transform our world.    ",
    ],
  },
  arabianSun: {
    background: "feature",
    isReverse: true,
    extraBtnText: "DOWNLOAD ISSUE (PDF, 3.7 MB)",
    date: " 15, 2020",
    title: "The Arabian Sun",
    text: "In this week's issue: Saudi Yamac hosting the annual International Petroleum Technology Conference",
    btnTxt: "Open Feature",
  },
  joinOurTeam: {
    background: "feature",
    isReverse: false,
    date: "CAREER",
    title: "Join our team",
    text: "Every day, our multi-national team of more than 70,000 people combines passion, purpose, and performance to achieve a common objective: unlocking the full potential of the Kingdom’s resources.",
    btnTxt: "Find Out More",
  },
};
