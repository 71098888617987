import React from "react";

const Overview = ({ Items }) => {
  return (
    <div className="blocks">
      <div className="container">
        <div className="row">
          {Items?.map((item) => (
            <div className="col-md-4">
              <div className="inner_block">
                <i className={item.icon}></i>
                <h2>{item.title}</h2>
                <p>{item.text}</p>
                <button className="btn btn-outline-light">overview</button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Overview;
