import React from "react";
import Banner from "../components/Banner";
import WhyClientsChooseUs from "../components/WhyClientsChooseUs";
import OverView from "../components/Overview";
import LatestNews from "../components/LatestNews";
import RecentNews from "../components/RecentNews";
import JoinOurTeamBanner from "../components/JoinOurTeamBanner";

const Home = ({ data }) => {
  return (
    <>
      <Banner {...data.banner} />
      <WhyClientsChooseUs {...data.whyClientsChooseUs} />
      <OverView Items={data.overviewItems} />
      <LatestNews content={data.latestNewsContent} />
      <RecentNews {...data.recentNews} />
      <JoinOurTeamBanner {...data.arabianSun} />
      <JoinOurTeamBanner {...data.joinOurTeam} />
    </>
  );
};

export default Home;
