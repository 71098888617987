import React from "react";

const WhoWeAre = ({ heading1, heading2, paragraphs1, image, paragraphs2 }) => {
  return (
    <div className="container">
      <h2 className="heading">{heading1}</h2>
      <div className="inner_text">
        <h5>{heading2}</h5>
        {paragraphs1.map((para) => (
          <p>{para}</p>
        ))}
        <div className="text-center mb-4">
          <img src={`images/${image}`} alt="" className="img-fluid" />
        </div>
        {paragraphs2.map((para) => (
          <p>{para}</p>
        ))}
      </div>
    </div>
  );
};

export default WhoWeAre;
