import React from "react";

const ContactUs = ({ title, items }) => {
  return (
    <div className="col-md-4">
      <div className="card card-body bg-light">
        <h5>{title}</h5>
        <ul className="list-unstyled cont_info">
          {items.map((item) => (
            <li>
              <i className={item.icon}></i> {item.text}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ContactUs;
