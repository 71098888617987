import React from "react";

const Banner = ({ imageSource, videoSource, heading, paragraph, btnTxt }) => {
  return (
    <div>
      <div className="banner">
        {imageSource ? (
          <img
            src={`images/${imageSource}`}
            alt=""
            className="img-fluid w-100"
          />
        ) : (
          <video
            controls
            muted
            autoPlay
            loop
            playsInline
            className="banner_video"
            preload="auto"
          >
            <source src={`images/${videoSource}.mp4`} type="video/mp4" />
            <source src={`images/${videoSource}.mp4`} type="video/ogg" />
            Your browser does not support the video tag.
          </video>
        )}

        <div className="banner_caption">
          <div className="container">
            <div className="col-md-6">
              {heading ? <h1>{heading}</h1> : <></>}
              {paragraph ? <p>{paragraph}</p> : <></>}
              {btnTxt ? <button className="btn">{btnTxt}</button> : <></>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
