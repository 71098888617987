import React from "react";

const JoinOurTeamBanner = ({
  background,
  isReverse,
  extraBtnText,
  date,
  title,
  text,
  btnTxt,
}) => {
  return (
    <div
      className="feature mb-0"
      style={{ backgroundImage: `url(images/${background}.jpg)` }}
    >
      <div className="row h-100 g-0">
        <div className={`col-md-5${isReverse ? " offset-md-7" : ""} h-100`}>
          <div className="details">
            <h5>{date}</h5>
            <h3>{title}</h3>
            <p>{text}</p>
            {extraBtnText ? (
              <button className="btn btn-primary">{extraBtnText}</button>
            ) : (
              <></>
            )}
            <button className="btn btn-outline-light">{btnTxt}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinOurTeamBanner;
