import React from "react";

const Map = ({ link }) => {
  return (
    <div className="map">
      <iframe
        _ngcontent-utn-c9=""
        allowfullscreen=""
        frameborder="0"
        height="450"
        src={link}
        style={{ border: 0 }}
        width="100%"
      ></iframe>
    </div>
  );
};

export default Map;
