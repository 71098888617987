import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { getBasicDetails } from "../store/actions/adminActions";

const Index = ({ children }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    getBasicDetails(setData);
  }, []);

  return (
    <div>
      <Header data={data} />
      {children}
      <Footer data={data} />
    </div>
  );
};
export default Index;
