import React from "react";
import Banner from "../components/Banner";
import WhoWeAreComp from "../components/WhoWeAre";
import JoinOurTeamBanner from "../components/JoinOurTeamBanner";

const WhoWeAre = ({ data }) => {
  return (
    <>
      <Banner {...data.banner} />
      <WhoWeAreComp {...data.whoWeAre} />
      <JoinOurTeamBanner {...data.arabianSun} />
      <JoinOurTeamBanner {...data.joinOurTeam} />
    </>
  );
};

export default WhoWeAre;
