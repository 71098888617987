import React from "react";
import Layout from "../layout";
import CareerScreen from "../screens/Career";

const Career = ({ data }) => {
  return (
    <Layout>
      <CareerScreen data={data} />
    </Layout>
  );
};

export default Career;
