import React, { useState } from "react";

const LatestNews = ({ content }) => {
  const [selectedTab, setSelectedTab] = useState("oil");
  return (
    <div className="container">
      <h2 className="heading">{content.title}</h2>
      <div className="lattest_news">
        <ul className="nav nav-tabs" id="topic_tabs" role="tablist">
          {content.buttons.map((btn) => (
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link${
                  selectedTab === btn.label ? " active" : ""
                }`}
                id={`${btn.label}-tab`}
                data-bs-toggle="tab"
                data-bs-target={`#${btn.label}-tab-pane`}
                type="button"
                role="tab"
                onClick={() => setSelectedTab(btn.label)}
                aria-controls={`${btn.label}-tab-pane`}
                aria-selected={selectedTab === btn.label}
              >
                {btn.text}
              </button>
            </li>
          ))}
        </ul>

        <div className="tab-content" id="topic_tabsContent">
          {content.tabs.map((tab, index) => (
            <div
              className={`tab-pane fade${
                selectedTab === tab.label ? " show active" : ""
              }`}
              id={`${tab.label}-tab-pane`}
              role="tabpanel"
              aria-labelledby={`${tab.label}-tab`}
              tabIndex={index}
            >
              <div className="row">
                {tab.items.map((Item) => (
                  <div className="col-md-4">
                    <a href="#" className="news_link">
                      <div className="inner_news">
                        <div className="img_area">
                          <img
                            src={`images/${Item.image}`}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <h5>{Item.date}</h5>
                        <h4>{Item.title}</h4>
                        <p>{Item.text}</p>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LatestNews;
