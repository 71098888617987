export default {
  name: "CAREER",
  route: "/career",
  banner: {
    imageSource: "career_banner.png",
    heading: "Join Yamac",
    paragraph:
      "When we put our energy to work, we enable others to seize opportunities that can revolutionize the world.",
  },
  FAQs: {
    QAs: [
      {
        title:
          "How do I apply for a job with yamac and do I need to have experience?It is very easy! All you need to do is:",
        text: "Submit your CV below or send your CV to info@hmshomes.com and have previous sales/estate agency experience, attach a covering letter along with a digital photo of yourself.",
      },
      {
        title: "How will I know if my CV has been successful?",
        text: "A member of our recruitment team will be in touch to arrange an initial interview either over the phone, Face Time or Skype. If you meet the requirements we are looking for we will then arrange a second interview before offering you a position. Every successful candidate will be expected to start employment within 4-6 weeks of being offered the job.",
      },
      {
        title: "How much money will I earn?",
        text: "This is entirely up to you. This is a very vibrant, fast paced and tough market to work in however if you are willing to adapt, work hard and become a specialist in your area then your earning potential is limitless.",
      },
      {
        title: "Are there any other benefits to working for yamac?",
        text: "Yes, you will be targeted monthly, quarterly and yearly. Every time you hit your targets you will be rewarded. Your rewards will always be agreed with you in advance and can range from a designer handbag or a tailored suit to a weekend in New York or a Rolex watch.",
      },
      {
        title: "Do you have positions available now?",
        text: "We are constantly looking for positive, enthusiastic people to help grow our ever expanding business so if you are hungry to make money, not shy of hard work and are in need for a new challenge then please send us your CV.",
      },
    ],
    currentOpenings: {
      title: "Current Openings:",
      p1: "Property Sales Consultants at yamac will assist clients buy or sell land or ready properties.",
      items: [
        {
          title: "The focus of the role involves:",
          text: "Listing process – Ensuring all required due diligence is done before listing a property for sale with yamac. Comparing potential stock with others in the area and applying local and market knowledge.Negotiation – This takes place between the prospective buyers and sellers with the aim of achieving an agreeable price by both parties. Property Sales Consultants will also liaise with banks, mortgage brokers, surveyors, lawyers and other real estate agencies during transactions.",
        },
        {
          title: "Experience Required",
          text: "RERA certification (preferred but not essential)No less than two years of relevant experience Valid driver’s licenseExcellent customer service skillsEffective communication skills.",
        },
      ],
      btnTxt: "Send CV",
    },
  },
  contact: {
    title: "Contact us today and let one of our friendly agents help you.",
    items: [
      { icon: "fa fa-phone", text: "+1 23456 7999" },
      { icon: "fa fa-envelope", text: "info@yamac.com" },
    ],
  },
  arabianSun: {
    background: "feature",
    isReverse: true,
    extraBtnText: "DOWNLOAD ISSUE (PDF, 3.7 MB)",
    date: " 15, 2020",
    title: "The Arabian Sun",
    text: "In this week's issue: Saudi Yamac hosting the annual International Petroleum Technology Conference",
    btnTxt: "Open Feature",
  },
  joinOurTeam: {
    background: "feature",
    isReverse: false,
    date: "CAREER",
    title: "Join our team",
    text: "Every day, our multi-national team of more than 70,000 people combines passion, purpose, and performance to achieve a common objective: unlocking the full potential of the Kingdom’s resources.",
    btnTxt: "Find Out More",
  },
};
