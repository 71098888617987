import React from "react";
import Layout from "../layout";
import HomeScreen from "../screens/Home";

const Home = ({ data }) => {
  return (
    <Layout>
      <HomeScreen data={data} />
    </Layout>
  );
};

export default Home;
