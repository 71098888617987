import React from "react";

const ContactPanel = ({ info, writeToUs }) => {
  return (
    <div className="container">
      <div className="contact">
        <div className="row no-gutters top_items">
          {info.map((item) => (
            <div className="col-md-4">
              <div className="item">
                <i className={item.icon}></i>
                {item.paragraphs.map((para) => (
                  <p>{para}</p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="contact_form">
        <h3>{writeToUs.title}</h3>
        <div className="row">
          {writeToUs.inputs.map((input) => (
            <div className="col-md-6">
              <div className="form-floating mb-3">
                <input
                  type={input.type}
                  className="form-control"
                  id="cont1"
                  placeholder={input.placeholder}
                />
                <label for="cont1">{input.label}</label>
              </div>
            </div>
          ))}

          <div className="col-md-12">
            <div className="form-floating mb-3">
              <textarea
                className="form-control"
                placeholder={writeToUs.subject}
                id="cont7"
                style={{ height: 100 }}
              ></textarea>
              <label for="cont7">{writeToUs.subject}</label>
            </div>
          </div>
        </div>

        <input type="submit" className="btn" value={writeToUs.btnTxt} />
      </div>
    </div>
  );
};

export default ContactPanel;
