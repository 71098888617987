import { baseUrl } from "../constants/baseUrl";
import axios from "axios";

export const getBasicDetails = async (setBasicDetails) => {
  try {
    const { data } = await axios.get(baseUrl + "basicDetails");
    setBasicDetails(data.data);
  } catch (e) {
    console.log(e);
  }
};
