import React, { useState } from "react";

const LatestNews2 = ({ label, items }) => {
  const [pageNo, setpageNo] = useState(1);
  return (
    <div className="container">
      <h2 className="heading">{label}</h2>
      <div className="row">
        {items
          .slice((items.length / 3) * (pageNo - 1), (items.length / 3) * pageNo)
          .map((item) => (
            <div className="col-md-4">
              <a href={`images/${item.image}`} className="gal_link news_link">
                <img
                  src={`images/${item.image}`}
                  alt=""
                  className="img-fluid"
                />
                <div className="inner_recent">
                  <p>{item.headline}</p>
                </div>
              </a>
            </div>
          ))}
      </div>
      <nav aria-label="Page navigation example" className="mt-5 mb-5">
        <ul className="pagination justify-content-center">
          <li
            className="page-item disabled"
            onClick={() => {
              if (pageNo > 1) {
                setpageNo(pageNo - 1);
              }
            }}
          >
            <a className="page-link">Previous</a>
          </li>
          <li
            className="page-item"
            onClick={() => {
              setpageNo(1);
            }}
          >
            <a className="page-link" href="#">
              1
            </a>
          </li>
          <li
            className="page-item"
            onClick={() => {
              setpageNo(2);
            }}
          >
            <a className="page-link" href="#">
              2
            </a>
          </li>
          <li
            className="page-item"
            onClick={() => {
              setpageNo(3);
            }}
          >
            <a className="page-link" href="#">
              3
            </a>
          </li>
          <li
            className="page-item"
            onClick={() => {
              if (pageNo < 3) {
                setpageNo(pageNo + 1);
              }
            }}
          >
            <a className="page-link" href="#">
              Next
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default LatestNews2;
