import React from "react";
import Layout from "../layout";
import ContactScreen from "../screens/Contact";

const Contact = ({ data }) => {
  return (
    <Layout>
      <ContactScreen data={data} />
    </Layout>
  );
};

export default Contact;
