import React from "react";
import Layout from "../layout";
import NewsScreen from "../screens/News";

const News = ({ data }) => {
  return (
    <Layout>
      <NewsScreen data={data} />
    </Layout>
  );
};

export default News;
