import React from "react";

const RecentNews = ({
  heading,
  recentNewsItems,
  heading2,
  mostReadNewsItems,
}) => {
  return (
    <div className="container">
      <div className="recent_news">
        <div className="row">
          <div className="col-md-8">
            <h2 className="heading">{heading}</h2>

            <div className="row">
              {recentNewsItems?.map((news) => (
                <div className="col-md-4">
                  <a href="#" className="news_link">
                    <div className="inner_recent">
                      <h5>
                        <i className="fa fa-map-marker-alt"></i> {news.location}{" "}
                        <i className="fa fa-calendar-alt"></i> {news.date}
                      </h5>
                      <p>{news.text}</p>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>

          <div className="col-md-4 bg-light mt-4">
            <div className="most_read">
              <h3>{heading2}</h3>
              {mostReadNewsItems?.map((news) => (
                <div className="inner_row">
                  <div className="time">
                    <h2>
                      {news.date} <small>{news.month}</small>
                    </h2>
                  </div>
                  <div className="news_tikker">
                    <p>
                      <a href="#">{news.headline}</a>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentNews;
