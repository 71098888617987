export default {
  name: "HOME",
  route: "/",
  banner: {
    videoSource: "video",
    heading: "Your Trust is Our Pride",
    paragraph:
      "When we put our energy to work, we enable others to seize opportunities that can revolutionize the world.",
    btnTxt: "Our Portfolio",
  },
  whyClientsChooseUs: {
    heading: "Why Clients Choose Us?",
    heading2: "ENERGY EFFICIENCY",
    heading3: "Wasit – the master gas system",
    paragraph:
      "Natural gas has high energy content and low emissions.Harnessing it allows us to save crude oil for higher value uses and grow our chemicals business.",
    btnTxt: "Open Features",
  },
  overviewItems: [
    { title: "25 years", text: "of experience", icon: "fa fa-broadcast-tower" },
    {
      title: "Global Clients",
      text: "Canada, Pakistan, Saudi Arabia, UAE and Kuwait",
      icon: "fa fa-globe",
    },
    { title: "100%", text: "Customer Satisfaction", icon: "fa fa-users-cog" },
  ],
  latestNewsContent: {
    title: "Latest news by topic",
    buttons: [
      { label: "oil", text: "OIL" },
      { label: "gas", text: "GAS" },
      { label: "bs", text: "BUSINESS SUSTAINABILITY" },
      { label: "chemical", text: "CHEMICALS" },
      { label: "tech", text: "TECHNOLOGY" },
      { label: "people", text: "YOUR PEOPLE" },
    ],
    tabs: [
      {
        label: "oil",
        items: [
          {
            image: "n1.jpg",
            date: "DHAHRAN, January 13, 2020",
            title:
              "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",

            text: "Yamac today hosted the opening ceremony of the International Petroleum Technology Conference (IPTC), the leading international oil and gas conference taking place from January 13-15 in Dhahran, Saudi Arabia.",
          },
          ,
          {
            image: "n2.jpg",
            date: "DHAHRAN, January 13, 2020",
            title:
              "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",

            text: "Yamac today hosted the opening ceremony of the International Petroleum Technology Conference (IPTC), the leading international oil and gas conference taking place from January 13-15 in Dhahran, Saudi Arabia.",
          },
          {
            image: "n3.jpg",
            date: "DHAHRAN, January 13, 2020",
            title:
              "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",

            text: "Yamac today hosted the opening ceremony of the International Petroleum Technology Conference (IPTC), the leading international oil and gas conference taking place from January 13-15 in Dhahran, Saudi Arabia.",
          },
        ],
      },
      {
        label: "gas",
        items: [
          {
            image: "n1.jpg",
            date: "DHAHRAN, January 13, 2020",
            title:
              "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",

            text: "Yamac today hosted the opening ceremony of the International Petroleum Technology Conference (IPTC), the leading international oil and gas conference taking place from January 13-15 in Dhahran, Saudi Arabia.",
          },
          ,
          {
            image: "n2.jpg",
            date: "DHAHRAN, January 13, 2020",
            title:
              "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",

            text: "Yamac today hosted the opening ceremony of the International Petroleum Technology Conference (IPTC), the leading international oil and gas conference taking place from January 13-15 in Dhahran, Saudi Arabia.",
          },
          {
            image: "n3.jpg",
            date: "DHAHRAN, January 13, 2020",
            title:
              "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",

            text: "Yamac today hosted the opening ceremony of the International Petroleum Technology Conference (IPTC), the leading international oil and gas conference taking place from January 13-15 in Dhahran, Saudi Arabia.",
          },
        ],
      },
      {
        label: "bs",
        items: [
          {
            image: "n1.jpg",
            date: "DHAHRAN, January 13, 2020",
            title:
              "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",

            text: "Yamac today hosted the opening ceremony of the International Petroleum Technology Conference (IPTC), the leading international oil and gas conference taking place from January 13-15 in Dhahran, Saudi Arabia.",
          },
          ,
          {
            image: "n2.jpg",
            date: "DHAHRAN, January 13, 2020",
            title:
              "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",

            text: "Yamac today hosted the opening ceremony of the International Petroleum Technology Conference (IPTC), the leading international oil and gas conference taking place from January 13-15 in Dhahran, Saudi Arabia.",
          },
          {
            image: "n3.jpg",
            date: "DHAHRAN, January 13, 2020",
            title:
              "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",

            text: "Yamac today hosted the opening ceremony of the International Petroleum Technology Conference (IPTC), the leading international oil and gas conference taking place from January 13-15 in Dhahran, Saudi Arabia.",
          },
        ],
      },
      {
        label: "chemical",
        items: [
          {
            image: "n1.jpg",
            date: "DHAHRAN, January 13, 2020",
            title:
              "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",

            text: "Yamac today hosted the opening ceremony of the International Petroleum Technology Conference (IPTC), the leading international oil and gas conference taking place from January 13-15 in Dhahran, Saudi Arabia.",
          },
          ,
          {
            image: "n2.jpg",
            date: "DHAHRAN, January 13, 2020",
            title:
              "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",

            text: "Yamac today hosted the opening ceremony of the International Petroleum Technology Conference (IPTC), the leading international oil and gas conference taking place from January 13-15 in Dhahran, Saudi Arabia.",
          },
          {
            image: "n3.jpg",
            date: "DHAHRAN, January 13, 2020",
            title:
              "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",

            text: "Yamac today hosted the opening ceremony of the International Petroleum Technology Conference (IPTC), the leading international oil and gas conference taking place from January 13-15 in Dhahran, Saudi Arabia.",
          },
        ],
      },
      {
        label: "tech",
        items: [
          {
            image: "n1.jpg",
            date: "DHAHRAN, January 13, 2020",
            title:
              "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",

            text: "Yamac today hosted the opening ceremony of the International Petroleum Technology Conference (IPTC), the leading international oil and gas conference taking place from January 13-15 in Dhahran, Saudi Arabia.",
          },
          ,
          {
            image: "n2.jpg",
            date: "DHAHRAN, January 13, 2020",
            title:
              "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",

            text: "Yamac today hosted the opening ceremony of the International Petroleum Technology Conference (IPTC), the leading international oil and gas conference taking place from January 13-15 in Dhahran, Saudi Arabia.",
          },
          {
            image: "n3.jpg",
            date: "DHAHRAN, January 13, 2020",
            title:
              "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",

            text: "Yamac today hosted the opening ceremony of the International Petroleum Technology Conference (IPTC), the leading international oil and gas conference taking place from January 13-15 in Dhahran, Saudi Arabia.",
          },
        ],
      },
      {
        label: "people",
        items: [
          {
            image: "n1.jpg",
            date: "DHAHRAN, January 13, 2020",
            title:
              "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",

            text: "Yamac today hosted the opening ceremony of the International Petroleum Technology Conference (IPTC), the leading international oil and gas conference taking place from January 13-15 in Dhahran, Saudi Arabia.",
          },
          ,
          {
            image: "n2.jpg",
            date: "DHAHRAN, January 13, 2020",
            title:
              "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",

            text: "Yamac today hosted the opening ceremony of the International Petroleum Technology Conference (IPTC), the leading international oil and gas conference taking place from January 13-15 in Dhahran, Saudi Arabia.",
          },
          {
            image: "n3.jpg",
            date: "DHAHRAN, January 13, 2020",
            title:
              "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",

            text: "Yamac today hosted the opening ceremony of the International Petroleum Technology Conference (IPTC), the leading international oil and gas conference taking place from January 13-15 in Dhahran, Saudi Arabia.",
          },
        ],
      },
    ],
  },
  recentNews: {
    heading: "Recent News",
    heading2: "Most Read",
    recentNewsItems: [
      {
        location: "DHAHRAN,",
        date: "Jan 13, 2020",
        text: "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",
      },
      {
        location: "DHAHRAN,",
        date: "Jan 13, 2020",
        text: "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",
      },
      {
        location: "DHAHRAN,",
        date: "Jan 13, 2020",
        text: "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",
      },
      {
        location: "DHAHRAN,",
        date: "Jan 13, 2020",
        text: "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",
      },
      {
        location: "DHAHRAN,",
        date: "Jan 13, 2020",
        text: "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",
      },
      {
        location: "DHAHRAN,",
        date: "Jan 13, 2020",
        text: "Yamac hosts International Petroleum Technology Conference in Saudi Arabia",
      },
    ],
    mostReadNewsItems: [
      {
        date: "09",
        month: "Nov",
        headline: "Yamac announces the publication of prospectus",
      },
      {
        date: "09",
        month: "Nov",
        headline: "Yamac announces the publication of prospectus",
      },
      {
        date: "09",
        month: "Nov",
        headline: "Yamac announces the publication of prospectus",
      },
      {
        date: "09",
        month: "Nov",
        headline: "Yamac announces the publication of prospectus",
      },
      {
        date: "09",
        month: "Nov",
        headline: "Yamac announces the publication of prospectus",
      },
    ],
  },
  arabianSun: {
    background: "feature",
    isReverse: true,
    extraBtnText: "DOWNLOAD ISSUE (PDF, 3.7 MB)",
    date: " 15, 2020",
    title: "The Arabian Sun",
    text: "In this week's issue: Saudi Yamac hosting the annual International Petroleum Technology Conference",
    btnTxt: "Open Feature",
  },
  joinOurTeam: {
    background: "feature",
    isReverse: false,
    date: "CAREER",
    title: "Join our team",
    text: "Every day, our multi-national team of more than 70,000 people combines passion, purpose, and performance to achieve a common objective: unlocking the full potential of the Kingdom’s resources.",
    btnTxt: "Find Out More",
  },
};
